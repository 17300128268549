/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customKeyCloakConfig } from "../utils/auth/keycloack";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";
import {
  TeamAction,
  UserAction,
  WorkplaceSettingAction,
} from "redux/store/action";
import {
  BILLING,
  BILLING_ALLOWED_ROUTES,
  DASHBOARD,
  // ONBOARDING,
  ME,
  PORTAL_ALLOWED_ROUTES,
  REDIRECT_TO_PORTAL,
  RESERVED_DOMAINS,
  SETTINGS,
  SIGNUP_INITIATE,
  SIGNUP_PHASE_8,
  BYPASS_DOMAIN_CHECK_ROUTES,
  CLEAR_COOKIES,
  accessControls,
  SCREENSHOT,
  CHOOSE_LOGIN_FLOW,
  REPORTS,
  reportingKeys,
} from "route/constant";
import { usePostHog } from "posthog-js/react";
import { redirectToPortal } from "utils/helper";
import { Client, AuthClient, extractPermissions } from "utils/transport";
import { permissionsMap } from "constants/constant";
import { notification } from "antd";
import sLogoai from "../assets/images/logo_ai.png";
import {
  fetchPlanGroup,
  onUpgradeModalOpen,
  setBillingErrorMessage,
  setBillingOverrides,
} from "redux/store/billing/action";
import UserDetail from "components/UserDetail";
import { fetchTaskActivities } from "redux/store/task-activities/action";
import GlobalTaskTimer from "common/GlobalTaskTimer";
import {
  analyticsGroupEvent,
  analyticsIdentifyEvent,
} from "utils/analyticsUtils";
import Banner from "common/Banner";
import { setBannerInfo } from "redux/store/tenant-settings/action";
import { fetchMeInfo } from "redux/store/users/action";
import { BILLING_MESSAGES, pathToFeatureKeyMap } from "utils/billingUtils";
import { fetchAppVersionDetails } from "redux/store/download/action";

export const AuthContext = React.createContext();

const AuthContextProvider = ({
  children,
  authRoute,
  requiredPermissions = [permissionsMap.TENANT_USER],
  homePage,
}) => {
  const navigate = useNavigate();
  let config = {};
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const {
    medetails_success,
    medetails_data,
    user_list_success,
    user_list_processing,
    medetails_recheck_failed,
    medetails_recheck_success,
    medetails_recheck_data,
    me_role_success,
    me_role_data,
  } = useSelector((state) => state.users);
  const { team_list_processing, team_list_success } = useSelector(
    (state) => state.team
  );
  const { tenant_list_data, tenant_list_success } = useSelector(
    (state) => state.tenant
  );
  const { billingErrorMessage, fetchSubscriptionSuccess, subscriptionsData } =
    useSelector((state) => state.billing);

  const { open, startDate, endDate, tab, identityId } = useSelector(
    (state) => state.userDetail
  );

  const { workplace_setting_data } = useSelector(
    (state) => state.workplaceSettings
  );

  const [permissions, setPermissions] = useState([permissionsMap.TENANT_USER]);
  const [ssoConfig, setSsoConfig] = useState([permissionsMap.TENANT_USER]);
  const [permissionsProcessingDone, setPermissionsProcessingDone] =
    useState(false);
  const [baseCred, setBaseCred] = useState({});
  const [apiUrlsSetupDone, setApiUrlsSetupDone] = useState(false);
  const [authKeycloakUrl, setAuthKeycloakUrl] = useState(
    process.env.REACT_APP_AUTH_ENDPOINT
  );
  const [coreApiUrl, setCoreApiUrl] = useState(
    process.env.REACT_APP_CONTROL_ENDPOINT
  );
  const [keycloakRealm, setKeycloakRealm] = useState(
    process.env.REACT_APP_AUTH_REALM
  );

  const [meta, setMeta] = useState({});

  const [errorKeycloak, setErrorKeycloak] = useState(false);
  const [KState, setKState] = useState({
    keycloak: null,
    authenticated: false,
  });

  const checkRequiredPermissions = (
    requiredPermissionsForModule = requiredPermissions,
    givenPermissions = permissions
  ) => {
    for (const requiredPermission of requiredPermissionsForModule) {
      if (givenPermissions.includes(requiredPermission)) {
        return true;
      }
    }
    return false;
  };

  const checkRequiredAccess = (path, upgradeMarker) => {
    return (
      checkRequiredSubscription(path, upgradeMarker) &&
      checkRequiredViewAccess(path)
    );
  };

  const checkRequiredSubscription = (path, upgradeMarker) => {
    return (
      BILLING_ALLOWED_ROUTES.includes(path) ||
      subscriptionsData?.plan?.plan_type?.plan_features?.features?.[
        pathToFeatureKeyMap[path]
      ]?.data ||
      (upgradeMarker &&
        subscriptionsData?.plan?.plan_type?.plan_features?.ui
          .feature_disable_mode !== "HIDE")
    );
  };

  const checkRequiredViewAccess = (path, subKey) => {
    if (
      path === SCREENSHOT &&
      !workplace_setting_data?.compliance?.enable_screenshots
    ) {
      return false;
    }
    if (path === REPORTS) {
      const reporting =
        me_role_data.role?.access_controls?.[accessControls?.[path]] || {};
      if (subKey) {
        return (
          me_role_success &&
          (!reporting?.hasOwnProperty(subKey) || reporting?.[subKey])
        );
      } else {
        return reportingKeys.reduce(
          (acc, key) =>
            acc || !reporting?.hasOwnProperty(key) || reporting?.[key],
          false
        );
      }
    }
    return (
      !path ||
      !accessControls[path] ||
      (me_role_success &&
        me_role_data.role?.access_controls?.[accessControls[path]])
    );
  };

  const getOriginDataJson = async () => {
    try {
      return await axios({
        // Endpoint to send files
        url: `${window.location.origin}/origin-data.json`,
        method: "GET",
        headers: {
          // Add any auth token here
          ContentType: "application/json",
        },
      });
    } catch (e) {
      return { data: {} };
    }
  };

  const getTenantByID = async (tenantID) => {
    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        "discover/tenant_by_id?id=" +
        tenantID,
      method: "GET",
      headers: {
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const getTenant = async (domain) => {
    const { data } = await getOriginDataJson();
    if (data?.tenant_by_base_domain?.[domain]?.apiGatewayUrl) {
      return { data: data.tenant_by_base_domain[domain] };
    }
    return await axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        "discover/tenant_by_base_domain?baseDomain=" +
        domain,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const fetchApiUrls = async () => {
    const params = new URLSearchParams(window.location.search.substring(1));
    const env = params.get("env") || process.env.REACT_APP_APP_ENV;

    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        `discover/shards_by_environment?environment=${env}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const fetchApiUrlsByShardId = async (shardID, domain) => {
    const { data } = await getOriginDataJson();
    if (data?.tenant_by_base_domain?.[domain]?.apiGatewayUrl) {
      return { data: data.tenant_by_base_domain[domain] };
    }
    return await axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        `discover/shards/${shardID}`,
      method: "GET",
      headers: {
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const fetchActiveTimer = (identityID) => {
    if (identityID) {
      dispatch(
        fetchTaskActivities({
          headers: getDefaultHeader(),
          body: {
            equals: {
              created_by_id: identityID,
            },
            isNotNull: ["start_time"],
            isNull: ["end_time"],
            hints: {
              "equals.created_by_id": "uuid",
            },
          },
          isTimer: true,
        })
      );
    }
  };

  const recheckMeInfo = () => {
    dispatch(fetchMeInfo({ headers: getDefaultHeader(), cached: true }));

    setTimeout(recheckMeInfo, 1200000);
  };

  useEffect(() => {
    if (
      medetails_recheck_failed ||
      (medetails_recheck_success &&
        (!medetails_recheck_data?.identity?.is_active ||
          (!medetails_recheck_data.tracking_settings.portal_access &&
            !checkRequiredPermissions([permissionsMap.TENANT_OWNER]))))
    ) {
      KeyCloaklogout("403");
    }
  }, [medetails_recheck_failed, medetails_recheck_success]);

  useEffect(() => {
    if (medetails_success) {
      const identityId = localStorage.getItem("identity_id");
      if (!identityId || identityId === "null" || identityId === "undefined") {
        localStorage.setItem("identity_id", medetails_data?.identity?.id);
      }
      const attachedGroupRole = medetails_data.attached_group_role;
      if (attachedGroupRole === "OWNER") {
        setPermissions([...permissions, permissionsMap.TENANT_MANAGER]);
      }
      setPermissionsProcessingDone(true);
      fetchActiveTimer(medetails_data?.identity?.id);
    }
  }, [medetails_success]);

  useEffect(() => {
    try {
      if (
        medetails_success &&
        tenant_list_success &&
        permissionsProcessingDone &&
        fetchSubscriptionSuccess &&
        subscriptionsData
      ) {
        try {
          posthog?.identify(medetails_data.identity?.id, {
            // name: `${medetails_data.identity?.first_name} ${medetails_data.identity?.last_name}`,
            email: medetails_data.identity?.email,
            // gender: medetails_data.gender,
            // phone_number: medetails_data.phone_number,
            // date_of_birth: medetails_data.date_of_birth,
            // date_of_joining: medetails_data.date_of_joining,
            tenant_id: tenant_list_data.id,
            tenant_name: tenant_list_data.name,
            tenant_base_domain: tenant_list_data.base_domain,
            billing_status: subscriptionsData?.billingStatus,
            tenant_created_date: tenant_list_data.created_date,
            // tenant_modified_date: tenant_list_data.modified_date,
            // designation: medetails_data.designation?.id,
            attached_group_role: medetails_data.attached_group_role,
            // attached_group: medetails_data.attached_group_id,
            // application_policy:
            //   medetails_data.attached_group?.application_policy?.id,
            // leave_policy: medetails_data.attached_group?.leave_policy?.id,
            // ...medetails_data.attached_group?.tracking_settings,
          });
        } catch (e) {
          console.error(e);
        }

        try {
          if (checkRequiredPermissions([permissionsMap.TENANT_OWNER])) {
            window.userGuiding.identify(medetails_data.identity?.id, {
              email: medetails_data.identity?.email,
              name: `${medetails_data.identity?.first_name} ${medetails_data.identity?.last_name}`,
              created_date: medetails_data.created_date,
              tenant_id: tenant_list_data.id,
              tenant_name: tenant_list_data.name,
              tenant_base_domain: tenant_list_data.base_domain,
              tenant_created_date: tenant_list_data.created_date,
              billing_status: subscriptionsData?.billingStatus,
              attachedGroupRole: medetails_data.attached_group_role,
              attachedGroup: medetails_data.attached_group_id,
              gender: medetails_data.gender,
              permissions,
            });
          }
        } catch (e) {
          console.error(e);
        }

        try {
          if (checkRequiredPermissions([permissionsMap.TENANT_OWNER])) {
            $zoho.salesiq.floatbutton.visible("show");
            $zoho.salesiq.visitor.name(
              `${medetails_data.identity?.first_name} ${medetails_data.identity?.last_name}`
            );
            $zoho.salesiq.visitor.email(medetails_data.identity?.email);
            $zoho.salesiq.visitor.uniqueuserid(medetails_data.identity?.id);
            $zoho.salesiq.visitor.id(medetails_data.identity?.id);
          } else {
            $zoho.salesiq.floatbutton.visible("hide");
          }
        } catch (e) {
          console.error(e);
        }

        try {
          analyticsIdentifyEvent(medetails_data.identity?.id, {
            name: `${medetails_data.identity?.first_name} ${medetails_data.identity?.last_name}`,
            email: medetails_data.identity?.email,
            permissions,
            attachedGroupRole: medetails_data.attached_group_role,
            attachedGroup: medetails_data.attached_group_id,
            gender: medetails_data.gender,
          });

          analyticsGroupEvent(tenant_list_data.id, {
            tenantId: tenant_list_data.id,
            tenantName: tenant_list_data.name,
            tenantBaseDomain: tenant_list_data.base_domain,
            tenantCreatedDate: tenant_list_data.created_date,
            billingStatus: subscriptionsData?.billingStatus,
          });
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    medetails_success,
    tenant_list_success,
    permissionsProcessingDone,
    fetchSubscriptionSuccess,
    subscriptionsData,
  ]);

  const getBaseDomain = () => {
    let subdomain = null;
    const data = window.location.hostname.split(".");

    if (data.length > 2) {
      subdomain = data[0];
      if (RESERVED_DOMAINS.includes(subdomain)) {
        if (REDIRECT_TO_PORTAL.includes(subdomain)) {
          redirectToPortal();
        } else if (!PORTAL_ALLOWED_ROUTES.includes(window.location.pathname)) {
          navigate(SIGNUP_INITIATE);
        }
        return null;
      } else if (
        BYPASS_DOMAIN_CHECK_ROUTES.includes(window.location.pathname)
      ) {
        return null;
      }
    } else {
      redirectToPortal();
    }
    return subdomain;
  };

  const getBaseCred = async (subdomain, redirectPath) => {
    const checkBaseDomain = subdomain || getBaseDomain();

    if (checkBaseDomain) {
      try {
        const { data: responseSave } = await getTenant(checkBaseDomain);

        // localStorage.setItem("rawData", JSON.stringify(rs.data))
        localStorage.setItem("TenantIdByBaseDomain", responseSave.tenantId);
        const headers = responseSave.headers || {};
        setMeta(responseSave?.meta || {});
        dispatch(setBannerInfo(responseSave?.meta?.banner));
        setSsoConfig(responseSave.ssoConfig);

        localStorage.setItem("headers", JSON.stringify(headers));

        Client.defaults.baseURL = responseSave.apiGatewayUrl;
        AuthClient.defaults.baseURL = `${responseSave.apiKeycloakUrl}/realms/${responseSave.apiKeycloakRealm}/protocol/openid-connect`;
        Client.defaults.headers.post = headers;

        setCoreApiUrl(responseSave.apiGatewayUrl);
        setAuthKeycloakUrl(responseSave.apiKeycloakUrl);
        setKeycloakRealm(responseSave.apiKeycloakRealm);
        setApiUrlsSetupDone(true);

        setBaseCred({ ...responseSave, redirectPath }); // setting subdomain triggers kc()
      } catch (er) {
        console.log("Base domain not found!", er);

        redirectToPortal();
      }
    } else if (
      window.location.pathname === SIGNUP_INITIATE ||
      window.location.pathname === SIGNUP_PHASE_8
    ) {
      fetchApiUrlsRetry();
    }
  };

  const fetchApiUrlsRetry = (count = 0) => {
    fetchApiUrls()
      .then((response) => {
        const length = response.data.length;
        const randomIndex = Math.ceil(Math.random() * length) - 1;
        const urlConfig = response.data[randomIndex];

        const headers = urlConfig.headers || {};
        dispatch(setBannerInfo(urlConfig?.meta?.banner));

        localStorage.setItem("headers", JSON.stringify(headers));

        Client.defaults.baseURL = urlConfig.api_gateway_url;
        AuthClient.defaults.baseURL = `${urlConfig.keycloak_url}/realms/${urlConfig.keycloak_realm}/protocol/openid-connect`;
        Client.defaults.headers.post = headers;

        setCoreApiUrl(urlConfig.api_gateway_url);
        setAuthKeycloakUrl(urlConfig.keycloak_url);
        setKeycloakRealm(urlConfig.keycloak_realm);
        setApiUrlsSetupDone(true);
      })
      .catch((err) => {
        if (count < 5) {
          setTimeout(fetchApiUrlsRetry, 2000, count + 1);
        } else {
          console.log(err);
          // notification.error({ message: SOMETHING_WENT_WRONG });
        }
      });
  };

  useEffect(() => {
    dispatch(fetchAppVersionDetails());
    getBaseCred();
  }, []);

  useEffect(() => {
    const kcIdpHint = localStorage.getItem("kc_idp_hint");
    if (ssoConfig?.allowDirectLogin && !kcIdpHint) {
      console.log("Direct login allowed");
      navigate(CHOOSE_LOGIN_FLOW);
    } else if (baseCred.tenantId && apiUrlsSetupDone) {
      kc();
    }
  }, [baseCred, apiUrlsSetupDone, ssoConfig]);

  // client token append
  useEffect(() => {
    if (KState.keycloak) {
      Client.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${KState.keycloak.token}`;
        return config;
      });
    }
  }, [KState]);

  const getRedirectURI = () => {
    const hostArray = window.location.hostname.split(".");
    hostArray[0] = baseCred.baseDomain;
    let pathName = window.location.pathname;

    if (!pathName || PORTAL_ALLOWED_ROUTES.includes(pathName)) {
      pathName = baseCred.redirectPath || "/";
    }

    return `${window.location.protocol}//${hostArray.join(".")}${
      window.location.host.split(":").length > 1
        ? `:${window.location.host.split(":")[1]}`
        : ""
    }${pathName}`;
  };

  const setCookies = (token = KState?.keycloak?.token) => {
    const date = new Date(); // Now
    date.setDate(date.getDate() + 30);

    const domainAndPort = window.location.origin
      .split(".")
      .slice(-2)
      .join(".")
      .split(":");
    // document.cookie = `token=${token};expires=${date};Domain=.${
    //   domainAndPort[0]
    // }${domainAndPort.length < 2 ? ";secure; httponly" : ""}`;
  };

  const kc = (idp) => {
    const idpHint = idp || localStorage.getItem("kc_idp_hint");
    if (baseCred.tenantId && apiUrlsSetupDone) {
      const baseCred_tenantID = baseCred.tenantId;

      try {
        config = customKeyCloakConfig(
          baseCred_tenantID,
          authKeycloakUrl,
          keycloakRealm
        );

        const options = {
          onLoad: "login-required",
          checkLoginIframe: false,
        };

        if (idpHint && idpHint != "generic" && ssoConfig?.allowDirectLogin) {
          options.adapter = { type: "idp", idpHint: idpHint };
        }

        // const loginUrl = config.createLoginUrl({
        //   redirectUri: getRedirectURI(),
        //   idpHint,
        // });
        // console.log("loginUrl", loginUrl);

        options.redirectUri = getRedirectURI();

        // var url = window.location.href.split(baseCred.baseDomain+".").join("")+"login/callback";
        config
          .init(options)
          .then((authenticated) => {
            setKState({ keycloak: config, authenticated });
            const extractedPermissions = extractPermissions(config.token);
            setPermissions(extractedPermissions);
            const isOwner = checkRequiredPermissions(
              [permissionsMap.TENANT_OWNER],
              extractedPermissions
            );
            if (isOwner) {
              dispatch(
                fetchPlanGroup({
                  headers: { Authorization: `Bearer ${config.token}` },
                })
              );
            }

            window.analytics.track("Login");

            const data = {
              clientId: config.clientId,
              token: config.token,
              isOwner,
            };

            setCookies(config.token);

            dispatch(setBillingOverrides(meta?.override_features || {}));

            dispatch(
              UserAction.fetchMeInfo({
                headers: { Authorization: `Bearer ${config.token}` },
              })
            );

            // dispatch(UserAction.MeDetailProcess(data));
            // dispatch(
            //   fetchSubscriptions({
            //     headers: { Authorization: `Bearer ${config.token}` },
            //     overrideFeatures: meta?.override_features || {},
            //   })
            // );

            // dispatch(TenantAction.TenantListProcess(data));

            dispatch(WorkplaceSettingAction.workplaceSettingProcess(data));
          })
          .catch((e) => {
            console.log("keycloakerror", e);
            setErrorKeycloak(true);
          });
      } catch (ex) {
        console.log("Error in CLient id", ex);
      }
    }
  };

  useEffect(() => {
    config.onTokenExpired = () => {
      config
        .updateToken(50)
        .then((refreshed) => {
          if (refreshed) {
            setKState({ keycloak: config, authenticated: refreshed });
            setCookies(config.token);
          }
        })
        .catch((ex) => {
          setErrorKeycloak(true);
          console.error("Failed to refresh token " + new Date() + ex);
        });
    };
  }, [config]);

  const keyCloakLogin = (subdomain, redirectPath = "/") => {
    try {
      return getBaseCred(subdomain, redirectPath);
    } catch (e) {
      console.error("Keycloak_login Authentication error", e);
    }
  };

  const KeyCloaklogout = (code = 0) => {
    if (KState.keycloak) {
      localStorage.removeItem("kc_idp_hint");
      KState.keycloak.createLogoutUrl = function () {
        return (
          KState.keycloak.endpoints.logout() +
          "?id_token_hint=" +
          KState.keycloak.idToken +
          "&post_logout_redirect_uri=" +
          encodeURIComponent(
            `${window.location.origin}${CLEAR_COOKIES}?code=${code}`
          )
        );
      };
      KState.keycloak.logout({
        redirectUri: encodeURIComponent(window.location.href),
      });
    }
  };

  useEffect(() => {
    if (KState.authenticated && permissionsProcessingDone) {
      if (homePage) {
        if (
          checkRequiredPermissions([
            permissionsMap.TENANT_OWNER,
            permissionsMap.TENANT_MANAGER,
          ])
        ) {
          navigate(DASHBOARD);
        } else {
          navigate(ME);
        }
      } else if (!checkRequiredPermissions()) {
        notification.warning({
          message: "Unauthorized Access!!",
          description:
            "You don't have the permission to access that page, please contact your admin",
        });

        navigate(ME);
      }
    }
    if (
      KState.authenticated &&
      permissionsProcessingDone &&
      !team_list_processing &&
      !team_list_success
    ) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
        isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
      };
      dispatch(TeamAction.TeamListProcess(data));
    }

    if (
      KState.authenticated &&
      permissionsProcessingDone &&
      !user_list_success &&
      !user_list_processing
    ) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
        isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
      };
      dispatch(UserAction.UserListProcess(data));
    }
  }, [requiredPermissions, permissionsProcessingDone, KState.authenticated]);

  // useEffect(() => {
  //   if (
  //     fetchSubscriptionSuccess &&
  //     workplaceSetting.workplace_setting_success &&
  //     subscriptionsData?.activeUsers < 4 &&
  //     permissionsProcessingDone &&
  //     checkRequiredPermissions([permissionsMap.TENANT_OWNER]) &&
  //     !(
  //       workplaceSetting?.workplace_setting_data?.prompts[
  //         "tenant:onboard_organization_Setting"
  //       ] &&
  //       workplaceSetting?.workplace_setting_data?.prompts[
  //         "tenant:onboard_complete"
  //       ]
  //     )
  //   ) {
  //     navigate(ONBOARDING);
  //   }
  // }, [
  //   permissionsProcessingDone,
  //   fetchSubscriptionSuccess,
  //   subscriptionsData,
  //   workplaceSetting.workplace_setting_success,
  // ]);

  const getDefaultHeader = () => {
    if (KState.keycloak) {
      return { Authorization: `Bearer ${KState.keycloak.token}` };
    }
    return {};
  };

  // Commented until the istio me/info 403 bug resolves
  useEffect(() => {
    if (fetchSubscriptionSuccess) {
      recheckMeInfo();

      setTimeout(() => {
        dispatch(fetchMeInfo({ headers: getDefaultHeader() }));
      }, 30000);
    }
  }, [fetchSubscriptionSuccess]);

  const getDomainAvailability = async (value) => {
    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        "discover/is_domain_available?baseDomain=" +
        value,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const doesDomainExist = async (value) => {
    try {
      const { data } = await getDomainAvailability(value);

      if (data.success) {
        return Promise.reject(new Error("Domain does not exist!!"));
      }

      return Promise.resolve();
    } catch (e) {
      // notification.error({
      //   message: (
      //     <div>
      //       Something went wrong, please contact support{" "}
      //       <a href={raiseTicketURL} target="_blank" rel="noreferrer">
      //         here
      //       </a>
      //     </div>
      //   ),
      // });
    }
  };

  const isDomainAvailable = async (value) => {
    try {
      const { data } = await getDomainAvailability(value);

      if (data.success) {
        return Promise.resolve();
      }

      return Promise.reject(new Error("Domain not available!!"));
    } catch (e) {
      // notification.error({
      //   message: (
      //     <div>
      //       Something went wrong please contact support{" "}
      //       <a href={raiseTicketURL} target="_blank" rel="noreferrer">
      //         here
      //       </a>
      //     </div>
      //   ),
      // });
    }
  };

  useEffect(() => {
    if (billingErrorMessage) {
      // notification.error({
      //   message: "Uh Oh!",
      //   description: billingErrorMessage,
      // });
      dispatch(setBillingErrorMessage(""));
    }
  }, [billingErrorMessage]);

  useEffect(() => {
    if (
      subscriptionsData &&
      fetchSubscriptionSuccess &&
      !BILLING_ALLOWED_ROUTES.includes(window.location.pathname) &&
      permissionsProcessingDone
    ) {
      if (
        ["CANCELLED", "PAUSED", "OVERDUE"].includes(
          subscriptionsData.billingStatus
        )
      ) {
        if (checkRequiredPermissions([permissionsMap.TENANT_OWNER])) {
          notification.warning({
            message: BILLING_MESSAGES.OWNER[subscriptionsData.billingStatus],
          });
          navigate(BILLING);
          if (subscriptionsData.billingStatus === "CANCELLED") {
            dispatch(onUpgradeModalOpen());
          }
        } else {
          notification.warning({
            message: BILLING_MESSAGES.USER[subscriptionsData.billingStatus],
          });
        }
      }

      if (
        new Date(subscriptionsData.expiryDate).getTime() < Date.now() ||
        subscriptionsData.billingStatus === "EXPIRED"
      ) {
        if (checkRequiredPermissions([permissionsMap.TENANT_OWNER])) {
          notification.warning({
            message: BILLING_MESSAGES.OWNER.EXPIRED,
          });
          navigate(BILLING);
          dispatch(onUpgradeModalOpen());
        } else {
          notification.warning({
            message: BILLING_MESSAGES.USER.EXPIRED,
          });
        }
      }

      if (subscriptionsData.activeUsers > subscriptionsData.totalAllowedUsers) {
        if (checkRequiredPermissions([permissionsMap.TENANT_OWNER])) {
          notification.warning({
            message: BILLING_MESSAGES.OWNER.LICENSE_LIMIT_EXCEEDED,
          });
          navigate(SETTINGS);
        } else {
          notification.warning({
            message: BILLING_MESSAGES.USER.LICENSE_LIMIT_EXCEEDED,
          });
        }
      }
    }
  }, [
    subscriptionsData,
    permissions,
    window.location.pathname,
    fetchSubscriptionSuccess,
  ]);

  const getDefaultScreenText = () => {
    if (checkRequiredPermissions([permissionsMap.TENANT_OWNER])) {
      if (
        ["CANCELLED", "PAUSED", "OVERDUE"].includes(
          subscriptionsData.billingStatus
        )
      ) {
        return BILLING_MESSAGES.OWNER[subscriptionsData.billingStatus];
      }
      if (
        new Date(subscriptionsData.expiryDate).getTime() < Date.now() ||
        subscriptionsData.billingStatus === "EXPIRED"
      ) {
        return BILLING_MESSAGES.OWNER.EXPIRED;
      }
      if (subscriptionsData.activeUsers > subscriptionsData.totalAllowedUsers) {
        return BILLING_MESSAGES.OWNER.LICENSE_LIMIT_EXCEEDED;
      }
    } else {
      if (
        ["CANCELLED", "PAUSED", "OVERDUE"].includes(
          subscriptionsData.billingStatus
        )
      ) {
        return BILLING_MESSAGES.USER[subscriptionsData.billingStatus];
      }
      if (
        new Date(subscriptionsData.expiryDate).getTime() < Date.now() ||
        subscriptionsData.billingStatus === "EXPIRED"
      ) {
        return BILLING_MESSAGES.USER.EXPIRED;
      }
      if (subscriptionsData.activeUsers > subscriptionsData.totalAllowedUsers) {
        return BILLING_MESSAGES.USER.LICENSE_LIMIT_EXCEEDED;
      }
    }

    return "A step towards productivity";
  };

  useEffect(() => {
    if (errorKeycloak) {
      kc();
    }
  }, [errorKeycloak]);

  return (
    <AuthContext.Provider
      value={{
        keyCloakLogin,
        getTenant,
        getTenantByID,
        fetchApiUrlsByShardId,
        KState,
        KeyCloaklogout,
        permissions,
        getDefaultHeader,
        setCookies,
        isDomainAvailable,
        apiUrlsSetupDone,
        authKeycloakUrl,
        coreApiUrl,
        keycloakRealm,
        doesDomainExist,
        checkRequiredPermissions,
        checkRequiredViewAccess,
        checkRequiredAccess,
        checkRequiredSubscription,
        kc,
        ssoConfig,
      }}
    >
      {permissionsProcessingDone &&
        checkRequiredPermissions([
          permissionsMap.TENANT_OWNER,
          permissionsMap.TENANT_MANAGER,
        ]) && <Banner />}
      {(fetchSubscriptionSuccess &&
        ((new Date(subscriptionsData.expiryDate).getTime() > Date.now() &&
          ["ACTIVE", "IN_TRIAL", "NON_RENEWING"].includes(
            subscriptionsData.billingStatus
          )) ||
          BILLING_ALLOWED_ROUTES.includes(window.location.pathname)) &&
        (subscriptionsData.activeUsers <= subscriptionsData.totalAllowedUsers ||
          BILLING_ALLOWED_ROUTES.includes(window.location.pathname)) &&
        KState.authenticated &&
        permissionsProcessingDone &&
        checkRequiredPermissions() &&
        checkRequiredViewAccess(window.location.pathname)) ||
      authRoute ? (
        children
      ) : (
        <center>
          <div className="auth_layout">
            <div
              className="signup_blcok setpass_blcok"
              style={{ marginTop: "-80px" }}
            >
              <div
                className="s_top"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "center",
                  border: "0px solid #f00",
                }}
              >
                <div className=" cir_logo">
                  <div className="logo_ai">
                    <img src={sLogoai} />
                  </div>
                </div>
                <center style={{ marginTop: "25px" }}>
                  <div style={{ marginBottom: "15px", fontWeight: "600" }}>
                    We360.ai
                  </div>

                  <div>{getDefaultScreenText()}</div>
                </center>
              </div>
            </div>
          </div>
        </center>
      )}
      {open && (
        <UserDetail
          open={open}
          identityId={identityId}
          sDate={startDate}
          eDate={endDate}
          tab={tab}
        />
      )}
      <GlobalTaskTimer />
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
